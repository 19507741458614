import { i18n } from '@/plugins/i18n'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueImg from 'v-img'
import VueCarousel from 'vue-carousel'
import VueCountdown from '@chenfengyuan/vue-countdown'
import ScrollAnimation from './directives/scrollAnimation'

Vue.directive('animate', ScrollAnimation);
Vue.config.productionTip = false
Vue.component(VueCountdown.name, VueCountdown)
Vue.use(VueImg)
Vue.use(VueCarousel)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY,
    libraries: 'places',
  }
})

new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
