export default {
  // Welcome Banner
  'You Are Cordially Invited!': 'Dengan Penuh Hormat, Sukacita Menjemput Biskita Hadir',
  'The Wedding Celebration of': 'Majlis Perkahwinan',
  'Open Invitation': 'Buka Jemputan',
  'We Are Getting Married': 'Kami Kan Kahwin',
  verseQuote: `“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berfikir.“`,
  'Inviting You To The Wedding Of': 'Menjemput Biskita Untuk Hadir Ke Majlis Perkahwinan Di Antara',
  'We are delighted to have you be present during our special day! Please contact us to RSVP.': 'Kami mengalu-alukan biskita untuk hadir pada hari isitimewa kami!',

  // Navbar
  Home: 'Utama',
  'Our Story': 'Cerita Kita',
  'Gallery': 'Galeri',
  'When & Where': 'Bila & Dimana',

  // Countdown Timer
  "Our Wedding Countdown Begins": "Majlis Kami Akan Bermula Dalam",
  'Just some...': 'Masa...',
  '...until we get married!': '...lagi!',
  'days': 'hari',
  'hours': 'jam',
  'minutes': 'minit',
  'seconds': 'saat',

  // Gallery
  'Happy Moments': 'Moment Bahagia',
  'All': 'Semua',
  'Photos': 'Foto',
  'Hide': 'Tutup',
  'View All': 'Lihat Semua',

  // When & Where
  'Solemnization And Wedding Reception': 'Majlis Akad Nikah dan Bersanding',
  'Sunday, 10th September 2023': 'Hari Ahad, 10hb September 2023',
  'Mutiara Ballroom, Level 5': 'Mutiara Grand Ballroom, Tingkat 5',
  'View location': 'Lihat lokasi',

  // RSVP
  rsvpDesc : "Kami Sangat Teruja Untuk Meraikan Hari Istimewa Kami Bersama Awda! Sila Balas Sebelum 30 Ogos 2024.",
  'Guest(s) Name': 'Nama Tetamu',
  'Will you attend?': 'Adakah anda akan hadir?',
  'So Delighted To Attend': 'Sangat Bergembira Menghadiri',
  "Unable To Attend": "Tidak Dapat Hadir",
  "Number of Guests": "Bilangan Tetamu",
  'Leave Us A Wedding Message': 'Tinggalkan kami mesej perkahwinan',

  // RSVP Message Template
  greeting: "Salam dan selamat sejahtera",
  guestName: "Nama tetamu",
  's': '',
  guestNumber: "Bilangan tetamu",
  attendMessage: "Saya gembira untuk mengatakan bahawa saya akan menghadiri hari istimewa anda!",
  attendMessageWithGuest1: "Saya gembira untuk mengatakan bahawa kami akan menghadiri hari istimewa anda dan akan membawa",
  attendMessageWithGuest2: "tetamu lebih dengan saya!",
  notAttendMessage: "Saya malangnya tidak dapat hadir tetapi saya doakan yang terbaik untuk acara ini!",
  bestWishes: "Berikut adalah ucapan selamat saya untuk kamu",

  // Footer
  'Thank You': 'Terima Kasih'
}