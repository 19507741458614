export default {
  // Welcome Banner
  'You Are Cordially Invited!': 'You Are Cordially Invited!',
  'The Wedding Celebration of': 'The Wedding Celebration of',
  'Open Invitation': 'Open Invitation',
  'We Are Getting Married': 'We Are Getting Married',
  verseQuote: `“And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. 
  And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.“`,
  'Inviting You To The Wedding Of': 'Inviting You To The Wedding Of',
  'We are delighted to have you be present during our special day! Please contact us to RSVP.': 'We are delighted to have you be present during our special day!',

  // Navbar
  Home: 'Home',
  'Our Story': 'Our Story',
  'Gallery': 'Gallery',
  'When & Where': 'When & Where',

  // Countdown Timer
  "Our Wedding Countdown Begins": "Our Wedding Countdown Begins",
  'Just some...': 'Just some...',
  '...until we get married!': '...until we get married!',
  'days': 'days',
  'hours': 'hours',
  'minutes': 'minutes',
  'seconds': 'seconds',

  // Gallery
  'Happy Moments': 'Happy Moments',
  'All': 'All',
  'Photos': 'Photos',
  'Hide': 'Hide',
  'View All': 'View All',

  // When & Where
  'Solemnization And Wedding Reception': 'Solemnization and Wedding Reception',
  'Sunday, 10th September 2023': 'Sunday, 10th September 2023',
  'Mutiara Ballroom, Level 5': 'Mutiara Grand Ballroom, Level 5',
  'View location': 'View location',

  // RSVP
  rsvpDesc : "We Are So Excited To Celebrate Our Special Day With You! Kindly Respond By August 30th, 2024.",
  'Guest(s) Name': 'Guest(s) Name',
  'Will you attend?': 'Will you attend?',
  'So Delighted To Attend': 'So Delighted To Attend',
  "Unable To Attend": "Unable To Attend",
  "Number of Guests": "Number of Guests",
  'Leave Us A Wedding Message': 'Leave Us A Wedding Message',

  // RSVP Message Template
  greeting: "Hello and good day!",
  guestName: "Guest name",
  's': 's',
  guestNumber: "Number of guests",
  attendMessage: "I'm happy to say that I'll be able to attend your special day!",
  attendMessageWithGuest1: "I'm happy to say that we'll be able to attend your special day and will be bringing",
  attendMessageWithGuest2: "additional guests with me!",
  notAttendMessage: "I'm unfortunately won't be able to attend but I wish you all the best for the event!",
  bestWishes: "Here's my best wishes message for you guys",

  // Footer
  'Thank You': 'Thank You'
}