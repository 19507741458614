import Vue from 'vue'
import VueRouter from "vue-router"
import { i18n } from '@/plugins/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang',
    component: {
      render: h => h('router-view')
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import("@/views/Home")
      }
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  if ( !['en','ms_BN'].includes(lang) ) {
    return next(localStorage.getItem('appLang') || 'en');
  }
  if ( i18n.locale !== lang ) {
    i18n.locale = lang
    localStorage.setItem('appLang', lang)
  }

  return next()
})

export default router
